import axios from "axios";
import history from "../history";
import { API_BASE_URL } from "../Configs/AppConfig";
import { AUTH_TOKEN } from "../Constants/constant";

const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000,
});

// Config
const ENTRY_ROUTE = "/auth/login";
const TOKEN_PAYLOAD_KEY = "token";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
service.interceptors.request.use(
    (config) => {
        const jwtToken = sessionStorage.getItem(AUTH_TOKEN);
        if (jwtToken) {
            config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
        }

        if (
            !jwtToken &&
            !config.headers[PUBLIC_REQUEST_KEY] &&
            !isLoginRelatedApi(config)
        ) {
            history.push(ENTRY_ROUTE);
            window.location.reload();
        }

        return config;
    },
    (error) => {
        // Do something with request error here
        // notification.error({
        //   message: "Error",
        // });
        alert({
            message: "Error",
        });
        Promise.reject(error);
    }
);

// API response interceptor
service.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        let notificationParam = {
            message: "",
        };

        // Remove token and redirect
        if (error?.response?.status === 401 || error?.response?.status === 403) {
            notificationParam.message = "Authentication Fail";
            notificationParam.description = "Please login again";
            sessionStorage.removeItem(AUTH_TOKEN);
            history.push(ENTRY_ROUTE);
            // window.location.reload();
            return;
        }

        return Promise.reject(error);
    }
);
const isLoginRelatedApi = (config) => {
    const { url } = config;
    return url.includes("/auth");
};
export default service;
