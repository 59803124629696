import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(sessionStorage.getItem('token') || null);
    const [userEmail, setUserEmail] = useState(sessionStorage.getItem('userEmail') || null);

    const login = (token, email) => {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('userEmail', email);
        setToken(token);
        setUserEmail(email);
    };

    const logout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userEmail');
        setToken('');
        setUserEmail('');
    };

    return (
        <AuthContext.Provider value={{ token, userEmail, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
