import React from 'react'

const Submit = () => {
    return (
        <button className='btn btn-primary' type='submit'>
            Generate
        </button>
    )
}

export default Submit