import React, { useReducer } from 'react';

function reducer(state, action) {
    switch (action.type) {
        case 'selected':
            return {
                selected: action.payload,
            };
        default:
            return state;
    }
}

const Tabs = ({ className = 'tabs-component', tabs = [], orientation = 'horizontal', type = 'tabs', onTabSelect, onQrCodeGenerated }) => {
    const [{ selected }, dispatch] = useReducer(reducer, {
        selected: 0,
    });

    const handleTabClick = (index) => {
        if (onTabSelect) {
            onTabSelect(index);
        }
        dispatch({ type: 'selected', payload: index });
    };

    const Panel = tabs && tabs.find((_, index) => index === selected);

    return (
        <div className={`${orientation === 'vertical' ? 'd-flex align-items-start ' : ''} ${className}`}>
            <div
                className={`nav${orientation === 'vertical' ? ' flex-column col-3 nav-pills me-4' : ` nav-${type} mb-4`}`}
                aria-orientation={orientation}
                role='tablist'
            >
                {tabs.map((tab, index) => (
                    <button
                        key={tab.label}
                        id={`tab-${index}`}
                        className={selected === index ? 'nav-link active' : 'nav-link'}
                        type='button'
                        role='tab'
                        tabIndex={selected === index ? 0 : -1}
                        aria-selected={selected === index}
                        aria-controls={`pane-${index}`}
                        onClick={() => handleTabClick(index)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>

            <div className={`tab-content${orientation === 'vertical' ? ' col-9' : ''}`}>
                <div
                    id={`pane-${selected}`}
                    className='tab-pane fade show active'
                    role='tabpanel'
                    aria-labelledby={`tab-${selected}`}
                >
                    {Panel?.Component && <Panel.Component index={selected} onQrCodeGenerated={onQrCodeGenerated} />}
                </div>
            </div>
        </div>
    );
};

export default Tabs;
