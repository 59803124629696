import React, { createContext, useState, useContext } from 'react';

// Create the context
const LoginModalContext = createContext();

// Create a provider component
export const LoginModalProvider = ({ children }) => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    const openLoginModal = () => setIsLoginModalOpen(true);
    const closeLoginModal = () => setIsLoginModalOpen(false);

    return (
        <LoginModalContext.Provider value={{ isLoginModalOpen, openLoginModal, closeLoginModal }}>
            {children}
        </LoginModalContext.Provider>
    );
};

// Create a custom hook to use the LoginModalContext
export const useLoginModal = () => useContext(LoginModalContext);
