import React, { useState, useEffect, useRef } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { IoReloadCircle } from "react-icons/io5";

const Captcha = ({ onVerify }) => {
    const [user, setUser] = useState({
        username: "",
    });
    const [captcha, setCaptcha] = useState("");
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const canvasRef = useRef(null);

    // Function to generate a random string
    const generateString = (length) => {
        let result = "";
        const characterLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * characterLength)
            );
        }
        return result;
    };

    // Function to draw the captcha on the canvas
    const drawCaptcha = (captchaText) => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");

            ctx.fillStyle = "black";
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            ctx.font = "20px Arial";
            ctx.fillStyle = "white";
            ctx.fillText(captchaText, 15, 25);
        }
    };

    useEffect(() => {
        const initialCaptcha = generateString(6);
        setCaptcha(initialCaptcha);
    }, []);

    useEffect(() => {
        drawCaptcha(captcha);
    }, [captcha]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    };

    const onSubmit = (e) => {
        const element = document.getElementById("succesBTN");
        const inputData = document.getElementById("inputType");

        element.textContent = "Checking...";
        inputData.disabled = true;
        element.disabled = true;

        setTimeout(() => {
            if (captcha === user.username) {
                element.style.color = "green";
                element.textContent = "Captcha verified";
                inputData.style.display = "none";
                onVerify(false);
            } else {
                element.style.color = "red";
                element.textContent = "Not Matched";

                setTimeout(() => {
                    element.style.color = "#1976d2";
                    element.textContent = "Verify Captcha";
                    inputData.disabled = false;
                    inputData.value = "";
                }, 1000);
                onVerify(true);
            }
            element.disabled = false;
        }, 1000);
    };

    const reloadCaptcha = () => {
        const newCaptcha = generateString(6);
        setCaptcha(newCaptcha);
    };

    return (
        <Row className="align-items-center">
            <Col xs="auto" style={{ marginTop: "5px" }}>
                <canvas id="canvas" height="35" width="100" ref={canvasRef}></canvas>
            </Col>
            <Col xs="auto" style={{ paddingLeft: "0px" }}>
                <IoReloadCircle size={25} style={{ cursor: "pointer" }} onClick={reloadCaptcha}>
                    Reload Captcha
                </IoReloadCircle>
            </Col>
            <Col xs="auto" style={{ padding: "0px" }}>
                <input
                    style={{ width: "180px" }}
                    id="inputType"
                    className="form-control"
                    placeholder="Enter Captcha"
                    type="text"
                    required
                    name="username"
                    onChange={handleChange}
                />
            </Col>
            <Col xs="auto" style={{ padding: "0px 10px" }}>
                <Button
                    variant="outline-primary"
                    type="button"
                    id="succesBTN"
                    onClick={onSubmit}
                >
                    Verify Captcha
                </Button>
            </Col>
        </Row>
    );
};

export default Captcha;
