import React from 'react';

function Footer() {
    return (
        <footer className="footer text-center">
            <span>
                Copyright © 2024 <a className="font-weight-semibold" href="https://jetatech.in" target="_blank" rel="noopener noreferrer">Jetatech</a> All Rights Reserved.
            </span>
        </footer>
    );
}

export default Footer;
