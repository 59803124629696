import { API_BASE_URL } from "../../Configs/AppConfig";
import fetch from "../../auth/fetchInterceptor";

const BASE_URL = API_BASE_URL;

export function requestCreateUser(data) {
    return fetch.post(`${BASE_URL}/api/auth/sign-up`, data);
}

export function requestUpdateUser(data) {
    return fetch.patch(`${BASE_URL}/api/public/update-user`, data);
}

export function requestUserDataById(data) {
    return fetch.get(`${BASE_URL}/api/app/user/getByIdUser/${data.id}`);
}
