import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaEye, FaEyeSlash, FaUserCircle } from 'react-icons/fa';
import { useAuth } from '../AuthContext';
import { useLoginModal } from '../LoginModalContext';
import { requestForgotPassword } from '../Services/forgotPassword';
import { requestLogin } from '../Services/login';
import { requestCreateUser } from '../Services/signup';
import AlertComponent from './Alert';
import Captcha from './Captcha';
import ChangePassword from './ChangePassword';
import Notification from './Notification';
import TermsConditionsModal from './TermsConditions';

function Header(props) {
    const { login, logout, userEmail } = useAuth();
    const { isLoginModalOpen, openLoginModal, closeLoginModal } = useLoginModal();
    const [isLogin, setIsLogin] = useState(true);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [notification, setNotification] = useState('');
    const [notificationVariant, setNotificationVariant] = useState('success'); // State to manage notification variant
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [isOpen, setIsOpen] = useState(false); // State for terms and conditions modal 
    const [SubmitButtonEnable, setSubmitButtonEnable] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);

    const handleClose = () => {
        closeLoginModal()
        setEmail("")
        setPassword("")
        setName("")
        setConfirmPassword("")
        setModalVisible(false)
    };

    const handleShow = () => openLoginModal();
    const toggleForm = () => setIsLogin(!isLogin);
    const toggleForgotPasswordForm = () => setForgotPassword(!forgotPassword);
    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const data = { email, password };
            const response = await requestLogin(data);
            const token = response.token; // Assuming response contains token
            const userEmail = response.data.email; // Extract email from response data
            login(token, userEmail);
            setIsLoggedIn(true); // Set login status
            setNotification(response?.message || "Login successfull !");
            setNotificationVariant('success'); // Set notification variant to success
            handleClose(); // Close the modal on successful login
        } catch (error) {
            setErrorMessage(error?.response?.data?.message);
            setNotificationVariant('danger'); // Set notification variant to error
        }
    };

    const handleSignUp = async (event) => {
        event.preventDefault();
        try {
            if (password !== confirmPassword) {
                setErrorMessage('Passwords do not match.');
                setNotificationVariant('danger'); // Set notification variant to error
                return;
            }
            const data = { name, email, password, confirmPassword };
            const response = await requestCreateUser(data);
            setIsLogin(true); // Switch to login form
            setEmail('');
            setPassword('');
            setName('');
            setConfirmPassword('');
            setSubmitButtonEnable(false)
            setSuccessMessage(response?.data?.message);
        } catch (error) {
            setErrorMessage(error?.response?.data?.message || error?.message || "Email already exist");
            setNotificationVariant('danger'); // Set notification variant to error
        }
    };

    const handleLogout = () => {
        logout(); // Call logout function from useAuth
        setIsLoggedIn(false);
        setEmail('');
        setPassword('');
        setNotification('You are successfully logged out.');
        setNotificationVariant('success'); // Set notification variant to success
        setShowDropdown(false);
    };

    const handleChangePasword = () => {
        setModalVisible(true)
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        try {
            let finalData = {
                email: email
            };
            await requestForgotPassword(finalData).then(response => {
                let message = response?.data || response?.data?.data || 'Please check your email';
                setNotification(message);
                setNotificationVariant('success'); // Set notification variant to success
                setEmail('');
                handleClose(); // Close the modal on email sent
            })
        }
        catch (e) {
            let message = e?.response?.data?.message || e?.response?.data?.data || 'Failed to send email';
            setErrorMessage(message);
            setNotificationVariant('danger'); // Set notification variant to error
        };
    };

    const toggleTermsConditions = () => {
        setIsOpen(!isOpen);
    };

    const handleCloseTerms = () => {
        setIsOpen(false)
    }

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    const onVerifyDataFromChild = (values) => {
        setSubmitButtonEnable(values);
    };

    return (
        <header className="header">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center py-2">
                    <div className="qr-logo">
                        <h1 className="h5 mb-0 fw-light">
                            <img
                                src="/img/jetatechlogo.png"
                                alt="Jetatech logo"
                                style={{ objectFit: 'contain' }}
                            />
                        </h1>
                    </div>
                    {props?.currentRoute !== '/email-verification' && notification && (
                        <Notification
                            message={notification}
                            onClose={() => setNotification('')}
                            variant={notificationVariant}
                        />
                    )}
                    {props?.currentRoute !== '/email-verification' && (<div>
                        {isLoggedIn ? (
                            <div
                                className="position-relative"
                                onClick={() => setShowDropdown(!showDropdown)}
                                style={{ cursor: 'pointer' }}
                            >
                                <FaUserCircle size={30} className="me-2" />
                                {showDropdown && (
                                    <div className="dropdown-menu show" style={{ position: 'absolute', left: "-30px" }}>
                                        <div className="dropdown-item-text">{userEmail}</div>
                                        <div className="dropdown-item" onClick={handleLogout}>Logout</div>
                                        <div className="dropdown-item" onClick={handleChangePasword}>Change Password</div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <Button variant="primary" onClick={handleShow}>
                                Login
                            </Button>
                        )}
                    </div>)}

                </div>
            </div>
            <Modal show={isLoginModalOpen} onHide={handleClose} destroyOnClose={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{forgotPassword ? 'Forgot Password' : isLogin ? 'Login' : 'Sign Up'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {successMessage && (
                        <AlertComponent variant="success" message={successMessage} onClose={() => setSuccessMessage('')} />
                    )}
                    {errorMessage && (
                        <AlertComponent variant="danger" message={errorMessage} onClose={() => setErrorMessage('')} />
                    )}
                    {forgotPassword ? (
                        <form autoComplete="off" onSubmit={handleForgotPassword}>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <Button style={{ width: "100%" }} variant="primary" type="submit" >
                                Send Reset Link
                            </Button>
                        </form>
                    ) : (
                        <form onSubmit={isLogin ? handleLogin : handleSignUp} autoComplete="off">
                            {!isLogin && (
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                            )}
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3 position-relative">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className="form-control"
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <span
                                    onClick={togglePasswordVisibility}
                                    className='password-eye-icon'
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </span>
                            </div>
                            {!isLogin && (
                                <>
                                    <div className="mb-3 position-relative">
                                        <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                        <input
                                            type={showConfirmPassword ? "text" : "password"}
                                            className="form-control"
                                            id="confirmPassword"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            required
                                        />
                                        <span
                                            onClick={toggleConfirmPasswordVisibility}
                                            className='password-eye-icon'
                                        >
                                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                        </span>
                                    </div>
                                    <div>
                                        <Captcha onVerify={onVerifyDataFromChild} />
                                    </div>
                                    <div className="my-3">
                                        <p className="small text-muted">
                                            By clicking on Sign Up, you are accepting LnkPro's <a href="#" onClick={toggleTermsConditions}>Terms and Conditions</a>.
                                        </p>
                                    </div>
                                </>
                            )}
                            <Button style={{ width: "100%" }} variant="primary" type="submit" disabled={!isLogin && SubmitButtonEnable}>
                                {isLogin ? 'Login' : 'Sign Up'}
                            </Button>
                        </form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <div className="w-100 text-center">
                        {forgotPassword ? <p className='mb-0'>
                            <a href="#" className="text-primary" onClick={toggleForgotPasswordForm}>
                                Back To Login
                            </a>
                        </p>
                            : isLogin ? (
                                <p className='mb-0'>
                                    Don't have an account?{' '}
                                    <a href="#" className="text-primary" onClick={toggleForm}>
                                        Sign Up
                                    </a>
                                    <a href="#" className="text-primary" style={{ marginLeft: "100px" }} onClick={toggleForgotPasswordForm}>
                                        Forgot Password ?
                                    </a>
                                </p>
                            ) : (
                                <p className='mb-0'>
                                    Already have an account?{' '}
                                    <a href="#" className="text-primary" onClick={toggleForm}>
                                        Login
                                    </a>
                                </p>
                            )}
                    </div>
                </Modal.Footer>
            </Modal >
            <Modal show={modalVisible} onHide={handleClose} destroyOnClose={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ChangePassword setIsLoggedIn={setIsLoggedIn} setNotification={setNotification} setNotificationVariant={setNotificationVariant} handleClose={handleClose} setShowDropdown={setShowDropdown} />
                </Modal.Body>
            </Modal >
            <TermsConditionsModal isOpen={isOpen} handleCloseTerms={handleCloseTerms}></TermsConditionsModal>
        </header>
    );
}

export default Header;
