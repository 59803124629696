import React, { createContext, createRef, useEffect } from 'react';
import QRCodeStyling from 'qr-code-styling';

const canvasRef = createRef();

const qrCode = new QRCodeStyling({
    data: window.location.origin,
    image: `${window.location.origin}/scanme.svg`,

    imageOptions: {
        crossOrigin: 'anonymous'
    }
});

export const AppContext = createContext({
    qrCode,
    canvasRef
});

export default function ContextProvider({ children }) {
    useEffect(() => {
        if (canvasRef.current) {
            qrCode.append(canvasRef.current);
        }
    }, []);

    return <AppContext.Provider value={{ qrCode, canvasRef }}>{children}</AppContext.Provider>;
}
