import React from 'react'
import ReactDOM from 'react-dom'
import ContextProvider from './Context'
import App from './App'
import './bootstrap.min.css'
import './App.css'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './AuthContext';
import { LoginModalProvider } from './LoginModalContext'
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <AuthProvider>
        <LoginModalProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>,
        </LoginModalProvider>,
      </AuthProvider>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()