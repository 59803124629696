import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';

const AlertComponent = ({ message, variant, onClose, timeout = 2000 }) => {
    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                onClose(); // Call the onClose function to clear the alert
            }, timeout);

            return () => clearTimeout(timer); // Clear the timer when component unmounts or message changes
        }
    }, [message, timeout, onClose]);

    return (
        message && (
            <Alert variant={variant} onClose={onClose} dismissible>
                {message}
            </Alert>
        )
    );
};

export default AlertComponent;
